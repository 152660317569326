<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h3 class="h3-seo mt-5">{{ demanda.titulo }}</h3>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <form>
        <b-row class="mx-2">
          <b-col cols="12">
            <div class="mt-3">
              <b-alert :show="pagamento && demanda.comprovantePagamento === undefined" variant="warning">
                <font-awesome-icon icon="circle-exclamation" class="mr-2" />
                <b>Atenção!</b>
                <br />
                Essa demanda esta aguardando o pagamento !
              </b-alert>
            </div>
          </b-col>

          <b-col cols="9">
            <b-card class="mt-3">
              <div slot="header">
                <b-row>
                  <b-col cols="6">
                    <span class="h5">Solicitante: {{ demanda.usuario.nome }}</span>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <span class="h5 ">Protocolo: {{ demanda.nroProtocolo }}</span>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col cols="2" v-if="!hasHiddenField('kmInicial')">
                  <b-form-group label="KM Inicial:">
                    <b-form-input v-model="demanda.kmInicial" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="2" v-if="!hasHiddenField('kmFinal')">
                  <b-form-group label="KM Final:">
                    <b-form-input v-model="demanda.kmFinal" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="8" v-if="!hasHiddenField('cidade')">
                  <b-form-group label="Cidade:">
                    <b-form-input v-model="demanda.cidade.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="7" v-if="!hasHiddenField('rodovia')">
                  <b-form-group label="Rodovia:">
                    <b-input-group>
                      <b-form-input v-model="demanda.trecho.descricao" :readonly="true" />
                      <b-input-group-append>
                        <b-button variant="info" @click="toggleMapDialog">
                          <i class="now-ui-icons location_pin" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="5" v-if="!hasHiddenField('tipo')">
                  <b-form-group :label="labelTipo">
                    <b-form-input v-model="demanda.tipo.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6" v-if="!hasHiddenField('objetivo')">
                  <b-form-group :label="labelObjetivo">
                    <b-form-input v-model="demanda.objetivo.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6" v-if="!hasHiddenField('local')">
                  <b-form-group label="Local:">
                    <b-form-input :value="getLocalDemanda()" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6" v-if="!hasHiddenField('area')">
                  <b-form-group label="Área:">
                    <b-form-input v-model="demanda.area.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="!hasHiddenField('nomenclaturaAlca')">
                  <b-form-group label="Nomenclatura Alça:">
                    <b-form-input :value="getNomenclaturaAlca()" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6" v-if="!hasHiddenField('faixa')">
                  <b-form-group label="Faixa:">
                    <b-form-input :value="getFaixaDemanda()" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6" v-if="!hasHiddenField('sentido')">
                  <b-form-group label="Sentido:">
                    <b-form-input :value="getSentido()" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="!hasHiddenField('observacao')">
                  <b-form-group label="Observações:">
                    <b-form-textarea v-model="demanda.observacoes" rows="3" :readonly="true" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="3">
            <b-card
              v-if="pagamento && (demanda.valorDemanda !== undefined || demanda.valorDemanda === 0)"
              class="mt-3"
              header-bg-variant="primary"
              footer-bg-variant="secondary"
            >
              <div slot="header" class="text-white">
                Informações de pagamento
              </div>
              <b-row v-if="pagamento">
                <b-col v-if="refCodeDemandas.value != undefined" cols="12">
                  <b-form-group label="Dados:">
                    <b-form-textarea v-model="refCodeDemandas.value" rows="3" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Valor:">
                    <b-form-input :value="getValorPagamento(demanda.valorDemanda)" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col v-if="demanda.valorAdicional != undefined" cols="12">
                  <b-form-group label="Valor adicional:">
                    <b-form-input :value="getValorPagamento(demanda.valorAdicional)" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Comprovante:">
                    <b-form-file
                      v-if="getTipoArquivoPagamento('CMP').anexo == undefined"
                      v-model="uploadedFileComprovante"
                      placeholder="Adicionar o comprovante"
                      browse-text="Escolher"
                      id="arquivo-comprovante"
                      name="arquivo-comprovante"
                      :accept="getTypeFile(1)"
                      @input="onUploadComprovante"
                    >
                    </b-form-file>
                    <div v-else>
                      <n-button
                        type="primary"
                        round
                        size="md"
                        class="mb-1"
                        @click="downloadDocto(getTipoArquivoPagamento('CMP').anexo)"
                      >
                        <font-awesome-icon icon="file-download" /> <span class="ml-1">Baixar</span>
                      </n-button>

                      <n-button
                        v-if="getTipoArquivoPagamento('NFC').anexo != undefined"
                        type="primary"
                        round
                        size="md"
                        class="mb-1 ml-1"
                        @click="downloadDocto(getTipoArquivoPagamento('NFC').anexo)"
                      >
                        <font-awesome-icon icon="file-download" /> <span class="ml-1">Nota fiscal</span>
                      </n-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <b-card header-bg-variant="primary" footer-bg-variant="secondary">
              <div slot="header" class="text-white">
                {{ demanda.statusInfo.descricao }}
              </div>

              <b-row class="my-1">
                <b-col cols="12">
                  <span class="p-2 text-muted"
                    ><i class="fas fa-location-arrow icon mr-2"></i>
                    {{ demanda.trecho.descricao + ', do KM ' + demanda.kmInicial + ' ao KM ' + demanda.kmFinal }}
                  </span>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <span class="badge badge-default">
              <i class="fas fa-comments mr-1 mt-1 mb-1" />
              Comentários ({{ historicos.length }})
            </span>
          </b-col>
          <b-col cols="12">
            <div class="mt-1 mb-1 mr-2">
              <p class="lead" v-if="historicos == undefined || historicos.length == 0">
                Não há comentários disponíveis nesta demanda.
              </p>
              <b-list-group>
                <b-list-group-item
                  v-for="hist in historicos"
                  :key="'hst-' + hist.id.sequencia"
                  class="flex-column align-items-start"
                  href="#"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <div>
                      <AccountDetails v-if="hist.origem == 1" class="mr-1" title="Concessionária" />
                      <AccountTie v-if="hist.origem == 2" class="mr-1" title="Agência reguladora" />
                      <AccountGroup v-if="hist.origem == 3" class="mr-1" title="Usuário(s) demandas" />
                      <span>{{ hist.descricao }}</span>
                    </div>
                    <span class="small">
                      <font-awesome-icon icon="calendar-alt" />
                      {{ hist.datHorHistorico | formatDate }}
                    </span>
                  </div>

                  <div v-if="hist.historicoResposta">
                    <span class="small ml-2"
                      >{{ hist.historicoResposta.datHorHistorico | formatDate }},
                      {{ hist.historicoResposta.usuario.nome }}:&nbsp;</span
                    >
                    {{ hist.historicoResposta.descricao }}
                  </div>

                  <div class="text-right" v-if="hist.anexo != undefined">
                    <a
                      href="#"
                      @click="downloadDocto(hist.anexo)"
                      v-b-tooltip.hover
                      title="Baixar"
                      class="nav-item"
                      target="_blank"
                    >
                      <font-awesome-icon icon="file-download" />
                      <span class="ml-1">Baixar</span>
                    </a>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <n-button type="primary" v-b-modal.publishModal round size="md" class="mb-1">
              <font-awesome-icon icon="comment" />&nbsp;Adicionar comentário
            </n-button>

            <b-modal
              id="publishModal"
              title="Incluir comentário"
              hide-footer
              scrollable
              size="xl"
              @show="getTiposArquivos"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-textarea
                    class="form-control mb-2"
                    rows="5"
                    cols="180"
                    placeholder="Adicionar comentário..."
                    v-model="comentario"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-table
                    class="mt-2"
                    :show-empty="true"
                    :fields="fieldsAnexos"
                    :items="anexos"
                    empty-text="Não há dados disponíveis."
                  >
                    <template #cell(nomeArquivo)="row">
                      <div v-if="row.item.obrigatorio == 1" class="font-weight-bold">
                        {{ row.item.nomeArquivo }}
                      </div>
                      <div v-else>
                        {{ row.item.nomeArquivo }}
                      </div>
                    </template>

                    <template #cell(tipoArquivo)="row">
                      {{ getTipoArquivo(row.item) }}
                    </template>

                    <template #cell(arquivo)="row">
                      <b-form-file
                        v-model="uploadedFile"
                        placeholder="Selecione o arquivo"
                        browse-text="Escolher"
                        :id="'arquivo-' + row.item.id.sequencia"
                        :name="'arquivo-' + row.item.id.sequencia"
                        :accept="getTypeFile(row.item.tipoArquivo)"
                        @input="onUploadAnexos"
                      >
                      </b-form-file>
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6"></b-col>
                <b-col cols="6" class="d-flex justify-content-end">
                  <n-button type="primary" round size="md" class="mr-3" @click="handlePublish">
                    <font-awesome-icon icon="comment" />
                    <span class="ml-1">Publicar</span>
                  </n-button>

                  <n-button type="secondary" round size="md" @click="handleCancel">
                    <font-awesome-icon icon="window-close" />
                    <span class="ml-1">Cancelar</span>
                  </n-button>
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>

        <b-modal id="mapModal" size="xl" title="Localização da demanda: ">
          <mapas :demanda="demanda" :kmlData="kmlData"></mapas>
        </b-modal>
      </form>

      <div class="separator separator-primary"></div>
    </div>

    <b-modal hide-footer hide-header size="sm" id="modalProcesso" centered>
      <b-row>
        <b-col>
          <div class="text-center text-info my-2 align-middle">
            <b-spinner class="align-middle"></b-spinner>
            <span class="ml-1">Aguarde, processando a requisição...</span>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Button, FormGroupInput } from '@/components';
import Mapas from '@/pages/Mapas.vue';
import AccountDetails from 'vue-material-design-icons/AccountDetails';
import AccountGroup from 'vue-material-design-icons/AccountGroup';
import AccountTie from 'vue-material-design-icons/AccountTie';

import numeral from 'numeral';
import 'numeral/locales';

export default {
  name: 'demanda',
  bodyClass: 'demanda-page',
  components: {
    AccountGroup,
    AccountDetails,
    AccountTie,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Mapas.name]: Mapas
  },
  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      formatter: undefined,
      idDemanda: 0,

      pagamento: false,
      refCodeDemandas: {},

      demanda: {
        titulo: null,
        nroProtocolo: null,
        kmInicial: null,
        kmFinal: null,
        objetivo: { descricao: null },
        cidade: { cidade: { descricao: null } },
        rodovia: { rodovia: { descricao: null } },
        trecho: { descricao: null },
        tipo: { descricao: null },
        area: { descricao: null },
        status: null,
        statusInfo: { descricao: null },
        usuario: { nome: null }
      },
      infoCidade: undefined,
      infoSegmento: undefined,
      historicos: [],
      kmlData: [],
      comentario: '',
      file: null,
      observacao: '',
      tipoAnexo: 'PB',
      tipoDemanda: this.$store.state.account.tipo,
      labelTipo: 'Tipo:',
      labelObjetivo: 'Objetivo:',

      hiddenFields: Vue.prototype.$externalConfig.HIDDEN_FIELDS,

      // Modal comentários
      files: [],
      anexos: [],
      fieldsAnexos: [
        { key: 'nomeArquivo', label: 'Nome/Descrição do arquivo' },
        { key: 'arquivo', label: 'Arquivo' },
        { key: 'tipoArquivo', label: 'Tipo' }
      ],
      uploadedFile: null,
      uploadedFileComprovante: null,

      optionsArquivo: [
        {
          id: 1,
          descricao: 'Arquivo PDF'
        },
        {
          id: 2,
          descricao: 'Documento Texto (DOCX)'
        },
        {
          id: 3,
          descricao: 'Planilha Eletrônica (XLSX)'
        },
        {
          id: 4,
          descricao: 'Imagem (PNG/JPG/BMP)'
        },
        {
          id: 5,
          descricao: 'PowerPoint (PPT)'
        },
        {
          id: 6,
          descricao: 'E-mail (MSG/EML)'
        },
        {
          id: 7,
          descricao: 'Arquivo compactado (ZIP/RAR)'
        },
        {
          id: 8,
          descricao: 'Projeto CAD (DWG/DXF)'
        },
        {
          id: 9,
          descricao: 'Arquivo KMZ'
        }
      ],

      anexosPagamento: []
    };
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual']),
    rows() {
      return this.dadosAdicionais.length;
    }
  },
  mounted() {
    if (window.config) {
      if (window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
      }

      if (window.config.LABELS) {
        this.labelTipo = window.config.LABELS.demanda.tipo;
        this.labelObjetivo = window.config.LABELS.demanda.objetivo;
      }
    }

    numeral.locale('pt-br');
    this.formatter = numeral;

    this.loadData();
  },
  methods: {
    downloadDocto(anexo) {
      console.log(anexo);
      this.$bvModal.show('modalProcesso');

      const token = this.$store.state.account.user;
      const requestCfg = {
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      const siglaEmpresa = this.$store.state.empresa.empresaAtual.sigla;
      const modulo = 'DEM';

      Vue.axios
        .get(`/downloads/${siglaEmpresa}/anexos/${modulo}/${anexo.uuid}`, requestCfg)
        .then(response => {
          this.downloadFile(new Blob([response.data]), anexo.nome);
          this.$bvModal.hide('modalProcesso');
        })
        .catch(error => {
          this.$bvModal.hide('modalProcesso');
          this.showErrorNotify('Erro', error.data ? error.data : error);
        });
    },
    downloadFile(blob, fileName) {
      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      console.log('fileName==>', fileName);
      if (fileName) link.download = fileName;
      link.target = '_blank';
      // some browser needs the anchor to be in the doc
      document.body.appendChild(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 6000);
    },
    getMediaUrl(idEmpresa, uuid) {
      const serverUrl =
        process.env.NODE_ENV === 'production' ? window.config.VUE_APP_API_LOCATION : process.env.VUE_APP_API_LOCATION;
      return `${serverUrl}/viewFile?idEmpresa=${idEmpresa}&uuid=${uuid}`;
    },
    getFaixaDemanda() {
      if (this.demanda == null || this.demanda === undefined) {
        return '';
      }

      if (this.demanda.faixa === 'FAIXA_1') {
        return '1';
      } else if (this.demanda.faixa === 'FAIXA_2') {
        return '2';
      } else if (this.demanda.faixa === 'FAIXA_3') {
        return '3';
      } else if (this.demanda.faixa === 'FAIXA_4') {
        return '4';
      } else if (this.demanda.faixa === 'FAIXA_5') {
        return '5';
      } else if (this.demanda.faixa === 'FAIXA_6') {
        return '6';
      } else if (this.demanda.faixa === 'ACOSTAMENTO_INTERNO') {
        return 'Acostamento Interno';
      } else if (this.demanda.faixa === 'ACOSTAMENTO_EXTERNO') {
        return 'Acostamento Externo';
      }

      return '';
    },
    getIndiceFmt(valor) {
      return numeral(valor).format('#,###.#');
    },
    getLocalDemanda() {
      if (this.demanda == null || this.demanda === undefined) {
        return '';
      }

      if (this.demanda.local === 'EIXO_TRONCAL') {
        return 'Eixo Troncal';
      } else if (this.demanda.local === 'MARGINAL_DIREITA') {
        return 'Marginal Direita';
      } else if (this.demanda.local === 'MARGINAL_ESQUERDA') {
        return 'Marginal Esquerda';
      } else if (this.demanda.local === 'DISPOSITIVO') {
        return 'Dispositivo';
      }

      return '';
    },
    getNomenclaturaAlca() {
      if (this.demanda == null || this.demanda === undefined) {
        return '';
      }

      const alcas = [];
      // Fill Nomenclaturas : Ramos
      for (let i = 100; i <= 900; i += 100) {
        alcas.push({ value: 'RAMO_' + i, text: 'Ramo ' + i });
      }

      // Fill Nomenclaturas : Rotatórias
      for (let i = 100; i <= 900; i += 100) {
        alcas.push({ value: 'ROTATORIA_', text: 'Rotatória ' + i });
      }

      for (let i = 0; i < alcas.length; i++) {
        if (alcas[i].value == this.demanda.nomenclaturaAlca) {
          return alcas[i].text;
        }
      }

      return '';
    },
    getSentido() {
      if (this.demanda.sentido == undefined) return '';
      if (this.demanda.sentido == 'N') return 'Norte';
      else if (this.demanda.sentido == 'S') return 'Sul';
      else if (this.demanda.sentido == 'L') return 'Leste';
      else if (this.demanda.sentido == 'O') return 'Oeste';
      else if (this.demanda.sentido == 'D') return 'Decrescente';
      else if (this.demanda.sentido == 'C') return 'Crescente';
    },
    loadData() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const idDemanda = this.$route.params.idDemanda;

      Vue.axios
        .get(`/faixasDominio/${sigla}/${idDemanda}`, options)
        .then(response => {
          this.demanda = response.data.demanda;

          if (this.demanda.objetivo.pagamento == 'S') {
            this.pagamento = true;
            this.getRefCodeDemandas();
          }

          if (this.demanda.usuario == undefined) {
            this.demanda.usuario = {};
          }

          if (response.data.demanda.area == undefined) {
            if (response.data.area == undefined) {
              this.demanda.area = {};
            }
          }

          if (response.data.historicos != undefined) {
            this.historicos = response.data.historicos;
          }

          if (response.data.cidade != undefined) {
            this.infoCidade = response.data.cidade;
          }

          if (response.data.segmento != undefined) {
            this.infoSegmento = response.data.segmento;
          }

          this.getAnexosByFaixaDominio();
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
    },
    getRefCodeDemandas() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const refName = 'DEMANDA.PAGAMENTO_OBSERVACAO';

      Vue.axios
        .get(`/core/refCodes/${sigla}?search=${refName}`, options)
        .then(response => {
          if (response.data.length != 0) {
            this.refCodeDemandas = response.data[0];
          }
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
    },
    handleCancel() {
      this.$bvModal.hide('publishModal');
    },
    handlePublish() {
      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const token = this.$store.state.account.user;
      const idDemanda = this.$route.params.idDemanda;
      const requestOpts = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      if (this.comentario == '') {
        this.$notify({
          group: 'msgs',
          title: 'Error',
          text: 'Digite o comentário',
          type: 'error'
        });
        return;
      }

      for (const arq of this.files) {
        const formData = new FormData();
        formData.append('comentario', this.comentario);
        if (arq !== undefined && arq != null) {
          formData.append('file', arq);
          formData.append('tipoAnexo', this.tipoAnexo);
        }

        Vue.axios
          .post(`/faixasDominio/${sigla}/${idDemanda}/publicar`, formData, requestOpts)
          .then(response => {
            this.comentario = '';
            this.historicos = response.data;
            this.$notify({
              group: 'msgs',
              title: 'Catch',
              text: 'Comentário enviado com sucesso.',
              type: 'info'
            });

            this.$bvModal.hide('publishModal');
          })
          .catch(error => {
            this.$notify({
              group: 'msgs',
              title: 'Catch',
              text: error.response && error.response.data.message ? error.response.data.message : error,
              type: 'error'
            });
          });
      }
    },
    toggleMapDialog() {
      this.$bvModal.show('mapModal');
    },
    getTiposArquivos() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      this.anexos = [];

      if (this.demanda.idTipo !== null) {
        Vue.axios.get(`/demandas/${this.empresaAtual.sigla}/tipos/${this.demanda.idTipo}`, options).then(response => {
          const arquivos = response.data.arquivos;
          if (arquivos.length > 0) {
            for (const arquivo of arquivos) {
              this.anexos.push(arquivo);
            }
          }
        });
      }
    },
    getTipoArquivo(item) {
      let text = '';

      for (const arquivo of this.optionsArquivo) {
        if (arquivo.id === item.tipoArquivo) {
          text = arquivo.descricao;
        }
      }

      return text;
    },
    getTypeFile(typeFile) {
      let type = '';

      if (typeFile === 1) {
        // Arquivo PDF
        type = '.pdf';
      } else if (typeFile === 2) {
        // Documento de Texto (DOCX)
        type = '.doc, .docx';
      } else if (typeFile === 3) {
        // Planilha Eletrônica (XLSX)
        type = '.xlsx, .xlsm, .xls, .csv';
      } else if (typeFile === 4) {
        // Imagem (PNG/JPG/BMP)
        type = 'image/*';
      } else if (typeFile === 5) {
        // PowerPoint (PPT)
        type = '.pptx, .ppt';
      } else if (typeFile === 6) {
        // E-mail (MSG/EML)
        type = '.eml, .msg';
      } else if (typeFile === 7) {
        // Arquivo Compactado (ZIP/RAR)
        type = '.bzip2, .gzip, .tar, .wim, .7z';
      } else if (typeFile === 8) {
        // Projeto CAD (DWG/DXF)
        type = '.dwg, .dxf';
      } else if (typeFile === 9) {
        // Arquivo KMZ
        type = '.kml, .kmz, .csv, .tsv, .gpx, .xlsx';
      }

      return type;
    },
    onUploadAnexos(files) {
      this.files.push(files);

      console.log('this.files=>', this.files);
    },

    onUploadComprovante(file) {
      console.log(file);

      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const token = this.$store.state.account.user;
      const requestOpts = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      const formData = new FormData();

      formData.append('file', file);

      Vue.axios
        .post(`/faixasDominio/${sigla}/${this.demanda.id.idDemanda}/anexos/comprovantes`, formData, requestOpts)
        .then(response => {
          console.log(response.data);
          this.loadData();
        })
        .catch(error => {
          const errorMsg = error.response ? error.response.data.message : error;
          this.$notify({
            group: 'msgsCenter',
            title: 'Erro',
            text: errorMsg,
            type: 'error'
          });
        });
    },

    getValorPagamento(valor) {
      return numeral(valor).format('###.##');
    },

    getAnexosByFaixaDominio() {
      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const token = this.$store.state.account.user;
      const requestOpts = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      Vue.axios
        .get(`/faixasDominio/${sigla}/${this.demanda.id.idDemanda}/anexos`, requestOpts)
        .then(response => {
          this.anexosPagamento = response.data;
        })
        .catch(error => {
          const errorMsg = error.response ? error.response.data.message : error;
          this.$notify({
            group: 'msgsCenter',
            title: 'Erro',
            text: errorMsg,
            type: 'error'
          });
        });
    },

    getTipoArquivoPagamento(tipo) {
      let anexo = {};
      for (const anx of this.anexosPagamento) {
        if (anx.tipoAnexo === tipo) {
          anexo = anx;
        }
      }
      return anexo;
    },

    hasHiddenField(fieldName) {
      if (this.hiddenFields == undefined || this.hiddenFields == null) return true;

      let hiddenFields = false;

      for (const value of this.hiddenFields) {
        if (value == fieldName) {
          hiddenFields = true;
        }
      }

      return hiddenFields;
    }
  }
};
</script>

<style scoped>
.listaPrioriza {
  list-style-type: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
