<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h2 class="h1-seo">FAIXA DE DOMÍNIO</h2>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <form @submit.prevent="onSubmit">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h3 class="title">Pesquisar demandas</h3>
            <p class="description">Sua demanda pode já existir. Por favor, pesquise antes de fazer o cadastramento.</p>
            <b-card>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <b-form-input
                      id="titulo"
                      name="titulo"
                      placeholder="Titulo"
                      v-model="titulo"
                      aria-describedby="titulo-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="titulo-feedback"
                      >É necessário informar o campo 'Título'.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <b-form-select
                      class="form-control"
                      id="objetivo"
                      name="objetivo"
                      v-model="objetivo"
                      aria-describedby="objetivo-feedback"
                    >
                      <option :value="null">{{ labelSelecionarObjetivo }}</option>
                      <option
                        v-for="(objetivo, idx) in getObjetivosByGrupo(tipoDemanda)"
                        :key="idx"
                        :value="objetivo.value.id"
                      >
                        {{ objetivo.text }}
                      </option>
                    </b-form-select>

                    <b-form-invalid-feedback id="objetivo-feedback">{{
                      labelInformarObjetivo
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="form-group">
                    <b-form-select
                      :options="getTipos()"
                      class="form-control"
                      id="tipo"
                      name="tipo"
                      v-model="tipo"
                      aria-describedby="tipo-feedback"
                    >
                    </b-form-select>

                    <b-form-invalid-feedback id="tipo-feedback">{{ labelInformarTipo }}</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <b-form-select
                      :options="areas"
                      class="form-control"
                      id="area"
                      name="area"
                      v-model="area"
                      aria-describedby="area-feedback"
                    ></b-form-select>
                    <b-form-invalid-feedback id="area-feedback">Selecione a 'Área' da demanda.</b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group>
                    <b-form-select
                      v-if="trecho == null"
                      class="form-control"
                      id="cidade"
                      name="cidade"
                      v-model="cidade"
                      aria-describedby="cidade-feedback"
                      @change="handleSelectCidade"
                    >
                      <option :value="null">Selecione a Cidade</option>
                      <optgroup v-for="(rdv, idxRdv) in getRodoviasByTrechos()" :key="idxRdv" :label="rdv">
                        <option v-for="(cidade, idx) in getCidadesByRodovia(rdv)" :key="idx" :value="cidade.value">
                          {{ cidade.text }}
                        </option>
                      </optgroup>
                    </b-form-select>

                    <b-form-select
                      v-if="trecho != null"
                      class="form-control"
                      id="cidade"
                      name="cidade"
                      v-model="cidade"
                      aria-describedby="cidade-feedback"
                      @change="handleSelectCidade"
                    >
                      <option :value="null">Selecione a Cidade</option>
                      <option v-for="(cidade, idx) in getCidades()" :key="idx" :value="cidade.value">
                        {{ cidade.text }}
                      </option>
                    </b-form-select>

                    <b-form-invalid-feedback id="cidade-feedback"
                      >Informe a 'Cidade' da demanda.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group>
                    <b-form-select
                      class="form-control"
                      id="trecho"
                      name="trecho"
                      v-model="trecho"
                      aria-describedby="trecho-feedback"
                    >
                      <option :value="null">Selecione a Rodovia</option>
                      <optgroup v-for="rodovia in getRodoviasByTrechos()" :key="rodovia.id" :label="rodovia">
                        <option v-for="trecho in getTrechosByRodovia(rodovia)" :key="trecho.id" :value="trecho.id">
                          {{ getTrechoInfo(trecho) }}
                        </option>
                      </optgroup>
                    </b-form-select>

                    <b-form-invalid-feedback id="trecho-feedback"
                      >Informe a 'Rodovia' da demanda.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <b-form-select
                      :options="status"
                      class="form-control"
                      id="status"
                      name="status"
                      v-model="statusSel"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-row>
              <b-col :cols="this.pesquisa == true ? 1 : 2"></b-col>
              <b-col :cols="this.pesquisa == true ? 5 : 8">
                <b-button type="submit" variant="primary" class="btn-round" block size="lg" @click="handlePesquisar">
                  <b-spinner small v-if="this.processing" />
                  {{ this.processing ? 'Aguarde...' : 'Pesquisar' }}
                </b-button>
              </b-col>
              <b-col cols="5">
                <b-button
                  type="submit"
                  variant="secondary"
                  class="btn-round"
                  block
                  size="lg"
                  v-if="this.pesquisa == true"
                  @click="handleCriarSolicitacao"
                >
                  <i class="now-ui-icons ui-1_email-85"></i>
                  Nova Demanda
                </b-button>
              </b-col>
              <b-col :cols="this.pesquisa == true ? 1 : 2"></b-col>
            </b-row>

            <b-row
              class="mt-1"
              v-if="pesquisa == true && (demandas === undefined || demandas == null || demandas.length == 0)"
            >
              <b-col cols="12" class="text-center">
                <b-alert show fade
                  >Não foram encontradas demanda(s) para o filtro informado. Você pode solicitar uma nova
                  demanda.</b-alert
                >
              </b-col>
            </b-row>
          </div>

          <b-row
            class="mt-1"
            v-if="pesquisa == true && demandas != undefined && demandas != null && demandas.length > 0"
          >
            <b-col>
              <b-button-group>
                <b-button variant="outline-secondary" :pressed="visao === 'LST'" @click="showLista">
                  <i class="now-ui-icons design_bullet-list-67"></i>
                  Lista
                </b-button>
                <b-button variant="outline-primary" :pressed="visao === 'MAP'" @click="showMapa()">
                  <i class="now-ui-icons location_pin"></i>
                  Mapa
                </b-button>
                <b-button variant="outline-primary" :pressed="visao === 'HEATMAP'" @click="showMapaCalor()">
                  <i class="now-ui-icons location_compass-05"></i>
                  Mapa calor
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>

          <b-row v-if="pesquisa == true && visao === 'LST'">
            <b-col cols="12">
              <b-card v-for="(area, idxArea) in getAreasByStatus()" :key="`card-${idxArea}`" :header="area.descricao">
                <b-list-group>
                  <b-list-group-item
                    v-for="solic in getDemandas(area)"
                    :key="'lst-' + solic.id.idDemanda"
                    :to="{ name: 'demanda', params: { idDemanda: solic.id.idDemanda } }"
                    class="flex-column align-items-start"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <span class="negrito small">
                        <font-awesome-icon icon="calendar-alt" />
                        {{ solic.datHorSolicitacao | formatDate }}&nbsp;-&nbsp;{{ solic.nroProtocolo }}
                      </span>
                      <span class="small">
                        <font-awesome-icon icon="map-marker-alt" />
                        {{ solic.cidade.descricao }}
                      </span>
                    </div>

                    <div class="d-flex w-100 justify-content-between">
                      <span class="ml-2">{{ solic.titulo }}</span>
                      <div class="text-right small">{{ getKmsDemanda(solic) }}</div>
                    </div>
                    <div class="d-flex ml-2 small" v-if="solic.observacoes != undefined">
                      <p class="overflow-wrap">{{ solic.observacoes }}</p>
                    </div>

                    <div class="text-right">
                      <router-link
                        :to="{ name: 'demanda', params: { idDemanda: solic.id.idDemanda } }"
                        class="nav-item"
                      >
                        <font-awesome-icon icon="envelope-open-text" />
                        <span class="ml-1">Abrir</span>
                      </router-link>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="pesquisa == true && visao === 'MAP'">
            <b-col cols="12">
              <mapas :demandas="demandas" :coordenadas="coordenadas" :rodovias="rodoviasCoordenadas"></mapas>
            </b-col>
          </b-row>
          <b-row v-if="pesquisa == true && visao === 'HEATMAP'">
            <b-col cols="12">
              <mapa-calor :demandas="demandas"></mapa-calor>
            </b-col>
          </b-row>
        </form>

        <div style="padding-top: 47px" v-if="this.pesquisa == false">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Button, FormGroupInput, Switch } from '@/components';
import Mapas from '@/pages/Mapas.vue';
import MapaCalor from '@/pages/MapaCalor.vue';
import { mapState } from 'vuex';

import numeral from 'numeral';
import 'numeral/locales';

export default {
  name: 'novaDemanda',
  bodyClass: 'novaDemanda-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Switch.name]: Switch,
    [Mapas.name]: Mapas,
    [MapaCalor.name]: MapaCalor,
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual']),
  },
  data() {
    return {
      backgroundTopo: "background-image: url('img/solicitacao.jpg')",
      pesquisa: false,
      processing: false,
      visao: 'LST',
      tipoDemanda: this.$store.state.account.tipo,
      tipoDemandaBase: 'FD', // cache para avaliação visual
      tipos: [{ value: null, text: this.labelSelecionarTipo }],
      areas: [{ value: null, text: 'Selecione a Área' }],
      objetivos: [{ value: null, text: this.labelSelecionarObjetivo }],
      cidades: [], // Array of cidades
      trechos: [], // Array of trechos
      status: [{ value: null, text: 'Selecione o Status' }],
      titulo: '',
      tipo: null,
      area: null,
      objetivo: null,
      cidade: null,
      trecho: null,
      statusSel: null,
      classificacao: null,
      kmInicial: '',
      kmFinal: '',
      observacao: '',
      demandas: [],
      coordenadas: [],
      rodoviasCoordenadas: [],
      labelSelecionarObjetivo: 'Selecione o Objetivo',
      labelInformarObjetivo: "Informe a 'Objetivo' da demanda.",
      labelSelecionarTipo: 'Selecione o Tipo',
      labelInformarTipo: "Informe o 'Tipo' da demanda",
    };
  },
  mounted() {
    numeral.locale('pt-br');

    if (window.config) {
      if (window.config.LABELS) {
        this.labelSelecionarObjetivo = window.config.LABELS.pesquisaDemandas.selecionarObjetivo;
        this.labelSelecionarTipo = window.config.LABELS.pesquisaDemandas.selecionarTipo;
        this.labelInformarObjetivo = window.config.LABELS.pesquisaDemandas.informarObjetivo;
        this.labelInformarTipo = window.config.LABELS.pesquisaDemandas.informarTipo;
      }

      if (window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
      }
    }

    this.tipoDemanda = 'FX';

    this.pesquisa = false;
    this.publica = false;
    this.fillData();
  },
  methods: {
    fillData() {
      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      Vue.axios.get(`/faixasDominio/${sigla}/dados`, options).then((response) => {
        this.tipos = [{ value: null, text: this.labelSelecionarTipo }];
        this.areas = [{ value: null, text: 'Selecione a Área' }];
        this.objetivos = [{ value: null, text: this.labelSelecionarObjetivo }];
        this.cidades = []; // array of cidades
        this.trechos = []; // array of trechos
        this.status = [{ value: null, text: 'Selecione o Status' }];
        this.classificacoes = [{ value: null, text: 'Todas demandas' }];

        const rowData = response.data;

        // Áreas
        if (rowData.areas) {
          for (var area of rowData.areas) {
            this.areas.push({ value: area.id, text: area.descricao });
          }
        }

        // Objetivos
        if (rowData.objetivos) {
          for (var obj of rowData.objetivos) {
            this.objetivos.push({ value: obj, text: obj.descricao });
          }
        }

        // Tipos
        if (rowData.tipos) {
          for (var tipo of rowData.tipos) {
            this.tipos.push({ value: tipo, text: tipo.descricao });
          }
        }

        // Trechos
        this.trechos = rowData.trechos;

        // Cidades
        this.cidades = rowData.cidades;

        // Status
        if (rowData.status) {
          for (var stat of rowData.status) {
            this.status.push({ value: stat.id, text: stat.descricao });
          }
        }

        // Classificação
        this.classificacoes.push({ value: 'P', text: 'Demandas Públicas' });
        this.classificacoes.push({ value: 'V', text: 'Demandas Privadas' });
      });
    },
    getAreasByStatus() {
      let areas = [];
      for (var demanda of this.demandas) {
        let found = false;
        for (var area of areas) {
          if (area.id === demanda.idAreaDemanda) {
            found = true;
            break;
          }
        }

        if (!found) {
          if (demanda.area != undefined) {
            areas.push(demanda.area);
          } else {
            let area = undefined;
            for (var ar of areas) {
              if (ar.id === 0) {
                area = ar;
                break;
              }
            }
            if (area == undefined) {
              areas.push({ id: 0, descricao: 'Área não definida' });
            }
          }
        }
      }
      return areas;
    },
    getCidades() {
      const cidadesData = [];

      if (this.cidades != undefined) {
        for (const cidade of this.cidades) {
          if (this.trecho != null && this.trecho !== undefined) {
            if (cidade.idTrecho != this.trecho) {
              continue;
            }
          }

          cidadesData.push({
            value: cidade,
            text: cidade.cidade.descricao + ' - [Km ' + cidade.kmInicial + ' ao Km ' + cidade.kmFinal + ']',
          });
        }

        cidadesData.sort((a, b) => {
          let cmp = a.value.kmInicial - b.value.kmInicial;
          if (cmp == 0) {
            cmp = ('' + a).localeCompare(b);
          }

          return cmp;
        });
      }

      return cidadesData;
    },
    getCidadesByRodovia(rodovia) {
      const cidadesData = [];
      for (const cidade of this.cidades) {
        if (
          cidade.trecho !== undefined &&
          cidade.trecho.rodovia !== undefined &&
          cidade.trecho.rodovia.descricao === rodovia
        ) {
          cidadesData.push({
            value: cidade,
            text: cidade.cidade.descricao + ' - [Km ' + cidade.kmInicial + ' ao Km ' + cidade.kmFinal + ']',
          });
        }
      }

      cidadesData.sort((a, b) => {
        let cmp = a.value.kmInicial - b.value.kmInicial;
        if (cmp == 0) {
          cmp = ('' + a).localeCompare(b);
        }

        return cmp;
      });

      return cidadesData;
    },
    getDemandas(area) {
      const data = [];

      if (area != null) {
        for (var demanda of this.demandas) {
          if (demanda.idAreaDemanda != undefined) {
            if (demanda.idAreaDemanda === area.id) {
              data.push(demanda);
            }
          } else {
            if (area.id == 0) data.push(demanda);
          }
        }
      }

      return data;
    },
    getGruposByObjetivos() {
      // Agrupa os Objetivos pelos Tipos (Demanda, Faixa Domínio)
      const data = [];

      const tipoDem = 'Demanda';
      const tipoFxa = 'Faixa Domínio';

      if (this.objetivos != null) {
        for (const objetivo of this.objetivos) {
          if (objetivo.value == null) continue;

          let tipoGrp = '';
          if (objetivo.value.grupo === 1) tipoGrp = tipoDem;
          else if (objetivo.value.grupo === 2) tipoGrp = tipoFxa;

          let found = false;
          for (var grpObj of data) {
            if (grpObj === tipoGrp) {
              found = true;
            }
          }

          if (!found) data.push(tipoGrp);
        }
      }

      return data;
    },
    getKmsDemanda(demanda) {
      let outKm = '';
      if (demanda.kmInicial != null) {
        outKm = outKm + demanda.kmInicial;
      }

      if (demanda.kmFinal != null) {
        if (outKm != '') {
          outKm = outKm + ' ao km ';
        }
        outKm = outKm + demanda.kmFinal;
      }

      if (outKm != '') {
        outKm = 'KM ' + outKm;
      }

      if (demanda.rodovia !== undefined && demanda.rodovia.rodovia != undefined) {
        outKm = demanda.rodovia.rodovia.descricao + ' / ' + outKm;
      }

      return outKm;
    },
    getObjetivosByGrupo(tipoDemanda) {
      if (tipoDemanda == undefined) return [];

      const grupo = tipoDemanda == 'DM' ? 1 : 2;

      const data = [];
      if (this.objetivos != null) {
        for (const objetivo of this.objetivos) {
          if (objetivo.value == null) continue;

          if (objetivo.value.grupo === grupo) {
            data.push(objetivo);
          }
        }
      }

      return data;
    },
    getRodoviasByTrechos() {
      const rodovias = [];

      if (this.trechos != undefined) {
        for (const trecho of this.trechos) {
          let found = false;
          for (const rodovia of rodovias) {
            if (rodovia == trecho.rodovia.descricao) {
              found = true;
              break;
            }
          }

          if (!found) {
            rodovias.push(trecho.rodovia.descricao);
          }
        }
      }

      return rodovias;
    },
    getTipos() {
      const data = [];
      data.push({ value: null, text: this.labelSelecionarTipo });

      for (const tipo of this.tipos) {
        if (tipo.value == null) continue;

        if (this.objetivo != null) {
          if (tipo.value.idObjetivo == this.objetivo) data.push(tipo);
        } else {
          //TODO: Remover este senao, devido a tipos somente serem carregados de um objetivo
          data.push(tipo);
        }
      }

      return data;
    },
    getTrechosByRodovia(rodovia) {
      const trechosData = [];

      if (this.trechos != undefined) {
        for (const trecho of this.trechos) {
          if (trecho.rodovia == null || trecho.rodovia == undefined) {
            continue;
          }

          if (trecho.rodovia.descricao === rodovia) {
            if (this.trechoCidade != null) {
              if (this.trechoCidade == trecho.id) {
                trechosData.push(trecho);
              }
            } else {
              // cidade nao escolhida, carrega todos
              trechosData.push(trecho);
            }
          }
        }
      }

      return trechosData;
    },
    getTrechoInfo(trecho) {
      if (trecho == null) {
        return null;
      }

      let trechoOutput = trecho.descricao;
      if (trecho.kmInicial != null && trecho.kmFinal != null) {
        trechoOutput +=
          ' - [KM ' +
          numeral(trecho.kmInicial).format('0.[00]') +
          ' ao KM ' +
          numeral(trecho.kmFinal).format('0.[00]') +
          ']';
      }

      return trechoOutput;
    },
    handleSelectCidade() {
      this.trechoCidade = null;
      for (const cid of this.cidades) {
        if (cid.id === this.cidade.id) {
          this.trechoCidade = cid.idTrecho;
          break;
        }
      }

      if (this.trechoCidade != null) {
        if (this.trecho == null || this.trecho !== this.trechoCidade) {
          this.trecho = this.trechoCidade;
        }
      }
    },
    handleCriarSolicitacao() {
      this.$router.push('/novaDemanda');
    },
    handlePesquisar() {
      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };
      const params = {};
      if (this.titulo != null && this.titulo != undefined && this.titulo != '') params['titulo'] = this.titulo;
      if (this.tipo != null && this.tipo != undefined) params['tipo'] = this.tipo.id;
      if (this.area != null && this.area != undefined) params['area'] = this.area;
      if (this.objetivo != null && this.objetivo != undefined) params['objetivo'] = this.objetivo;
      if (this.cidade != null && this.cidade != undefined) params['cidade'] = this.cidade.idCidade;
      if (this.trecho != null && this.trecho != undefined) params['trecho'] = this.trecho;
      if (this.statusSel != null && this.statusSel != undefined) params['status'] = this.statusSel;
      if (this.classificacao != undefined && this.classificacao != null)
        params['publica'] = this.classificacao == 'P' ? 1 : 0;

      // Deve garantir buscar sempre um dos tipos, se escolhido, ou todos
      let demFxa = this.tipoDemanda;
      if (demFxa != undefined && demFxa != 'DM;FD') params['grupo'] = demFxa == 'DM' ? 1 : 2;

      options['params'] = params;

      this.processing = true;
      Vue.axios
        .get(`/faixasDominio/${sigla}`, options)
        .then((response) => {
          this.demandas = response.data.demandas;
          this.coordenadas = response.data.coordenadas;
          this.rodoviasCoordenadas = response.data.rodovias;
          this.processing = false;
          this.pesquisa = true;
        })
        .catch((error) => {
          this.processing = false;
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error',
          });
        });
    },
    onSubmit() {},
    showLista() {
      this.visao = 'LST';
    },
    showMapa() {
      this.visao = 'MAP';
    },
    showMapaCalor() {
      this.visao = 'HEATMAP';
    },
  },
};
</script>

<style>
.overflow-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
</style>
